import { colors, radius, spacing } from '@lamimed/pantheon';
import styled from 'styled-components';

import { List as ListParent } from 'components/List';

const iconBoxSize = '4rem';

export const Grid = styled(ListParent.Wrapper)`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: ${spacing.small};
  margin-bottom: ${spacing.large};
`;

export const IconBox = styled.div`
  width: ${iconBoxSize};
  height: ${iconBoxSize};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.dark};
  color: ${colors.light};
  padding: ${spacing.small};
  border-radius: ${radius.small};

  svg {
    width: 100%;
  }
`;

export const List = styled.div`
  padding: ${spacing.medium};
  background: ${colors.light};
  border-radius: ${radius.small};
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
