import bwt from '/public/images/our_partners/bwt.png';
import cma from '/public/images/our_partners/cma.png';
import rocketMilano from '/public/images/our_partners/rocket-milano.png';

export const OurParnersImages = [
  {
    name: 'BWT',
    file: bwt,
  },
  {
    name: 'Rocket Espresso Milano',
    file: rocketMilano,
  },
  {
    name: 'CMA Industrial Technologies',
    file: cma,
  },
];
