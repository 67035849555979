import {
  Grid,
  NewTypography as Typography,
  RoundButton,
  useViewport,
} from '@lamimed/pantheon';
import { ShieldQuarter } from 'styled-icons/boxicons-regular';
import { HandHoldingHeart, Recycle, UserPlus } from 'styled-icons/fa-solid';
import { ChartMultiple } from 'styled-icons/fluentui-system-filled';
import { Fastapi } from 'styled-icons/simple-icons';

import { List } from 'components/List';

import * as S from './styles';

const contents = [
  {
    title: 'Benessere quotidiano',
    description: `
    Lami affianca le persone in modo smart nei loro bisogni di salute primari.
    `,
    icon: <HandHoldingHeart />,
  },
  {
    title: 'ESG',
    description: `
    In linea con i SDGs, mette la salute al centro, contribuendo concretamente
    alle strategie ESG delle aziende.
    `,
    icon: <Recycle />,
  },
  {
    title: 'Approccio data-driven',
    description: `
    Fornisce insight strutturati e facilita il processo decisionale del medico
    e l’interazione medico-paziente.
    `,
    icon: <ChartMultiple />,
  },
  {
    title: 'Efficienza',
    description: `
    Permette di risparmiare su tempi e costi di cura che impattano le persone,
    i medici e le aziende.
    `,
    icon: <Fastapi />,
  },
  {
    title: 'Inclusività',
    description: `
    Permette di svolgere un’azione a beneficio di ampie fasce di popolazione,
    aziendale e non solo.
    `,
    icon: <UserPlus />,
  },
  {
    title: 'Prevenzione',
    description: `
    Promuove un nuovo approccio alla salute e al benessere psico-fisico
    facilitando la prevenzione.
    `,
    icon: <ShieldQuarter />,
  },
];

export const AboutUs: React.FC = () => {
  const { greaterThan } = useViewport();

  return (
    <Grid.Wrapper>
      <Typography as="h2" marginBottom="small" variant="heading_2">
        Perché scegliere Lami
      </Typography>

      <Typography marginBottom={greaterThan('desktop') ? 'small' : 'medium'}>
        Come aiutiamo aziende, professionisti e pazienti.
      </Typography>

      <S.Grid>
        {contents.map(({ title, description, icon }) => (
          <S.List key={title}>
            <S.IconBox>{icon}</S.IconBox>

            <List.Title image={null} variant="heading_5"></List.Title>

            <Typography variant="heading_5" marginBottom="none">
              {title}
            </Typography>

            <Typography variant="body_text_3" marginBottom="none">
              {description}
            </Typography>
          </S.List>
        ))}
      </S.Grid>

      <S.BtnContainer>
        <RoundButton href="/richiedi-una-demo" as="a" expanded>
          Richiedi una demo
        </RoundButton>
      </S.BtnContainer>
    </Grid.Wrapper>
  );
};
