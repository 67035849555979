import { gap, isMobileLarge, media, radius, spacing } from '@lamimed/pantheon';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${gap(spacing.medium)};

  ${media.greaterThan('tablet')`
    flex-direction: row;

    ${gap(spacing.large)};
  `};
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 15rem;

  ${isMobileLarge()`
    height: 20rem;
  `}

  img {
    border-radius: ${radius.medium};
  }

  ${media.greaterThan('tablet')`
    height: 31.1rem;

    img {
      border-radius: ${radius.medium} ${radius.medium} 0 0;
    }
  `};
`;
