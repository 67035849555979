import {
  Button as ButtonParent,
  Grid,
  gutter,
  isMobileSmall,
  media,
  new_typography,
  NewTypography,
  spacing,
} from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

import { headerHeightDesktop } from 'components/Header/styles';
import { MainCallToAction as MainCallToActionParent } from 'components/MainCallToAction/styles';
import { TextWrapper as TextWrapperParent } from 'components/TextWrapper/styles';

interface HeroBackgroundProps {
  deviceHeight: number | undefined;
}

/**
 * NOTE: This function is needed because on some devices the browsers change the 100vh
 * value when user scroll down, to avoid flicks we set the fixed height.
 */
const mobileControlHeight = (size?: number) => css`
  height: ${size ? `${size}px` : '100vh'};
`;

export const HeroBackground = styled.div<HeroBackgroundProps>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ deviceHeight }) => mobileControlHeight(deviceHeight)};

  ${media.greaterThan('tablet')`
    height: calc(100vh - ${headerHeightDesktop});
    margin-top: ${headerHeightDesktop};
  `}

  ${media.greaterThan('desktop_large')`
    height: calc(100% - ${headerHeightDesktop});
  `}
`;

export const Wrapper = styled(Grid.Wrapper)`
  min-height: inherit;
`;

export const MainCallToAction = styled(MainCallToActionParent)`
  margin-bottom: ${spacing.xlarge};
  margin-top: 0;

  ${media.greaterThan('tablet')`
    min-height: inherit;
    display: grid;
    gap: ${gutter.default};
    grid-auto-flow: column;
    grid-template-columns: 2fr 1fr;
    margin-bottom: ${spacing.none};
  `}
`;

export const HeroImageWrapper = styled.div`
  padding-top: ${spacing.xlarge};

  ${media.greaterThan('tablet')`
    // NOTE: the width and height values are fixed to match figma layout
    width: 37.5rem;
    height: 40.2rem;
    display: block;
    grid-column: 2;
    margin-bottom: ${spacing.none};
    padding-top: ${spacing.none};
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan('tablet')`
    grid-column: 1;
  `}
`;

export const TextWrapper = styled(TextWrapperParent)`
  margin: ${spacing.none};
  gap: 0;

  ${media.greaterThan('tablet')`
    // TODO: fix styled-component stylesheet order issue
    && {
      max-width: 100%;
    }
  `}
`;

export const Button = styled(ButtonParent)`
  ${media.greaterThan('tablet')`
    max-width: 30rem;
  `}
`;

export const Title = styled(NewTypography)`
  ${new_typography.heading_1};

  ${isMobileSmall()`
    ${new_typography.heading_3};
    margin-bottom: ${spacing.none};
  `};
`;

export const Description = styled(NewTypography)`
  margin-bottom: ${spacing.medium};

  ${media.greaterThan('tablet')`
    margin-bottom: ${spacing.large};
  `};

  ${isMobileSmall()`
    margin-bottom: ${spacing.small};
  `};
`;
