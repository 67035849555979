import {
  Card,
  colors,
  gap,
  Grid,
  gutter,
  media,
  spacing,
} from '@lamimed/pantheon';
import Image from 'next/image';
import styled from 'styled-components';

export const LinkProxy = styled.a.attrs({
  target: '_blank',
})`
  display: block;
  text-decoration: none;
`;

export const Time = styled.div`
  display: flex;
  align-items: center;

  ${gap(gutter.small)}
`;

export const SlideWrapper = styled(Grid.Wrapper)`
  background-color: ${colors.light};

  // TODO: this should be a style for the BulletCarousel infinity effect prop
  ${media.lessThan('tablet')`
    padding-right: 0;

    .slider-slide:last-of-type {
      ${LinkProxy} {
        margin-right: ${spacing.medium};
      }
    }
  `}

  // NOTE: Compensates the height of the slider stepper
  padding-bottom: calc(${spacing.medium} + 0.4rem);

  ${media.greaterThan('tablet')`
    padding-bottom: calc(${spacing.medium} + 0.6rem);
  `}
`;

export const CardImage = styled(Image)`
  object-fit: cover;
`;

export const SeeMoreCard = styled(Card)`
  && {
    &::after {
      background: unset;
    }
  }
`;

export const CenterContent = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - (${spacing.medium} * 2));
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
