import { media, spacing } from '@lamimed/pantheon';
import styled from 'styled-components';

export const Wrapper = styled.div`
  // TODO: this should be a style for the BulletCarousel infinity effect prop
  .slider-control-bottomcenter {
    padding-right: ${spacing.medium};

    ${media.greaterThan('desktop')`
      padding-right: 0;
    `};
  }

  .slider-frame {
    ${media.lessThan('tablet')`
      width: calc(100% + ${spacing.medium}) !important;
      margin-left: -${spacing.medium};
      padding: 0 ${spacing.small};
    `}
  }
`;
