import React from 'react';
import {
  ArrowDown,
  colors,
  useUserDevice,
  useViewport,
} from '@lamimed/pantheon';

import { useElementScroll } from 'hooks/useElementScroll';

import * as S from './styles';

type Variant = 'minimal' | 'with_content';

export interface NextSectionButtonProps extends React.PropsWithChildren {
  variant?: Variant;
  nextSectionId: string;
  bgColor?: keyof typeof colors;
}

export const NextSectionButton: React.FC<NextSectionButtonProps> = ({
  variant = 'minimal',
  nextSectionId,
  bgColor,
  children,
}) => {
  const { greaterThan } = useViewport();
  const { browser } = useUserDevice();
  const { scrollToElement } = useElementScroll(greaterThan('tablet'));

  if (variant === 'with_content') {
    return (
      <S.Container
        bgColor={bgColor}
        isSafari={browser === 'Safari'}
        onClick={() => scrollToElement(nextSectionId)}
      >
        <S.ChevronIcon variant={variant}>
          <ArrowDown color="neutral" />
        </S.ChevronIcon>

        {children}
      </S.Container>
    );
  }

  return (
    <S.ChevronIcon
      variant={variant}
      onClick={() => scrollToElement(nextSectionId)}
    >
      <ArrowDown color="neutral" />
    </S.ChevronIcon>
  );
};
