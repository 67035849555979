import { NewTypography as Typography, spacing } from '@lamimed/pantheon';
import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
`;

export const ListItemTitle = styled(Typography)`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: ${spacing.small};
`;
