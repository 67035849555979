import { colors, media, opacity, radius, spacing } from '@lamimed/pantheon';
import Image from 'next/image';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  width: 100%;
`;

type ColumnProps = {
  marginBottom: keyof typeof spacing;
};

export const Column = styled.div<ColumnProps>`
  ${({ marginBottom = 'none' }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
    background-color: ${rgba(colors.light, opacity.extreme)};
    border-radius: ${radius.small};
    padding: ${spacing.small};
    margin-bottom: ${spacing[marginBottom]};
  `}
`;

export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  aligen-items: center;
  justify-content: center;
`;

export const Logo = styled(Image)`
  object-fit: contain;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  padding-bottom: ${spacing.medium};

  * {
    cursor: default;

    .slider-control-centerleft *,
    .slider-control-centerright * {
      cursor: pointer;
    }
  }

  ${media.greaterThan('tablet')`
    padding-bottom: ${spacing.none};
  `}
`;
