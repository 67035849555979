import { gap, spacing } from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

import { TextAlign } from '.';

interface Props {
  textAlign?: TextAlign;
  noMargin?: boolean;
}

const textWrapperModifiers = {
  left: css`
    width: 100%;
    align-items: flex-start;
  `,
  center: css`
    align-items: center;
  `,
};

export const TextWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.noMargin ? 0 : `${spacing.medium} 0 0 0`)};

  ${gap(spacing.small)};

  ${({ textAlign = 'center' }) => css`
    text-align: ${textAlign};
    ${textWrapperModifiers[textAlign]};
  `};

  > * {
    width: 100%;
  }
`;
