import {
  BulletCarousel,
  Grid,
  NewTypography as Typography,
  useViewport,
} from '@lamimed/pantheon';

import { getCarouselArrowSize } from 'utils/getCarouselArrowSize';

import { OurParnersImages } from './images';

import * as S from './styles';

export const OurPartners = () => {
  const { greaterThan } = useViewport();

  return (
    <Grid.Wrapper>
      <S.Container>
        <S.Title>
          <Typography as="h2" variant="heading_2" color="text__light">
            Credono in noi
          </Typography>

          <Typography
            color="text__light"
            marginBottom={greaterThan('desktop') ? 'small' : 'medium'}
          >
            Grazie ai partner che ci appoggiano nel nostro lavoro quotidiano:
          </Typography>
        </S.Title>

        <S.CarouselContainer>
          <BulletCarousel
            slidesToShow={greaterThan('tablet') ? 3 : 2}
            withoutControls={greaterThan('tablet')}
            slidesToScroll={1}
            cellSpacing={20}
            controlsTheme="light"
            arrowSize={getCarouselArrowSize(greaterThan('tablet'))}
          >
            {OurParnersImages.map(({ file, name }) => (
              <S.Column
                key={name}
                marginBottom={greaterThan('tablet') ? 'none' : 'medium'}
              >
                <S.LogoContainer>
                  <S.Logo
                    src={file}
                    alt={name}
                    height={25}
                    placeholder="blur"
                  />
                </S.LogoContainer>
              </S.Column>
            ))}
          </BulletCarousel>
        </S.CarouselContainer>
      </S.Container>
    </Grid.Wrapper>
  );
};
