import { colors, media, spacing } from '@lamimed/pantheon';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${colors.dark};
  padding-top: ${spacing.medium};
  // NOTE: 2.8rem represent the gap between slides and control(since controls are floating)
  padding-bottom: calc(${spacing.medium} + 2.8rem);

  ${media.greaterThan('tablet')`
    padding-top: ${spacing.large};
    padding-bottom: calc(${spacing.large} + ${spacing.medium});
  `};
`;

export const Category = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  ${media.greaterThan('tablet')`
    max-width: 17rem;
  `};
`;

export const CategoryIcon = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.light};
  border-radius: 50%;
  margin-bottom: ${spacing.small};

  ${media.greaterThan('tablet')`
    margin-bottom: ${spacing.medium};
  `};
`;
