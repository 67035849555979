import React from 'react';
import { new_typography } from '@lamimed/pantheon';
import Image from 'next/legacy/image';

import * as S from './styles';

interface ListItemTitleProps {
  image?: string | null;
  variant?: keyof typeof new_typography;
}

const ListItemTitle: React.FC<React.PropsWithChildren<ListItemTitleProps>> = ({
  image = '/images/illustration/list-icon.svg',
  variant = 'body_text_2',
  children,
}) => {
  return (
    <S.ListItemTitle variant={variant}>
      {!!image && <Image src={image} width={18} height={18} />}

      {children}
    </S.ListItemTitle>
  );
};

export const List = {
  Wrapper: S.List,
  Title: ListItemTitle,
};
