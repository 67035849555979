import { NewTypography as Typography } from '@lamimed/pantheon';

interface Props {
  title: string;
  description: string;
}

export const NewThumbnailDivider: React.FC<Props> = ({
  title,
  description,
}) => {
  return (
    <>
      <Typography as="h2" variant="heading_2">
        {title}
      </Typography>

      <Typography>{description}</Typography>
    </>
  );
};
