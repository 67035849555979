import {
  Card,
  NewTypography as Typography,
  RoundButton,
} from '@lamimed/pantheon';
import Image from 'next/image';

import * as S from '../../styles';

import seeMoreBackground from '/public/images/home/lami-news/see-more-background.jpg';

export const SeeMore = () => {
  return (
    <S.LinkProxy
      key="see-more"
      href="https://lamimed.it/lami-news-notizie-salute-online/"
    >
      <S.SeeMoreCard variant="hero">
        <S.CardImage
          src={seeMoreBackground}
          alt="lami blog"
          fill
          placeholder="blur"
          quality={100}
        />

        <Card.Body variant="hero">
          <S.CenterContent>
            <Image
              src="/images/brand/brand-light.svg"
              alt="lami blog"
              width={128}
              height={50}
              quality={100}
            />

            <Typography
              variant="body_text_3"
              color="text__light"
              marginBottom="none"
              marginTop="small"
              textAlign="center"
            >
              Vuoi leggere più contenuti? Vai sul nostro blog e ne troverai
              molti altri!
            </Typography>
          </S.CenterContent>

          <RoundButton icon="pluscircle" expanded reverse variant="light">
            Scopri di più
          </RoundButton>
        </Card.Body>
      </S.SeeMoreCard>
    </S.LinkProxy>
  );
};
