import { useRouter } from 'next/router';

import { useSWRGet } from 'hooks/useSWRGet';

import { sendToErrorTracking } from 'utils/sendToErrorTracking';

interface BlogPost {
  id: number;
  title: string;
  image: string;
  thumbnail_image: string;
  excerpt: string;
  url: string;
  reading_time: number;
  categories_slugs: string[];
}

const categoriesToInclude = [
  'salute-e-tecnologia',
  'innovazione-tecnologia',
  'sanita-societa',
  'corporate-business',
];

export const useGetPosts = () => {
  const router = useRouter();
  const { data, error } = useSWRGet<BlogPost[]>(
    'https://news.lamimed.it/wp-json/lami/posts',
  );

  if (error) {
    sendToErrorTracking({ error, where: 'StayUpdated', context: data });

    router.push('/500');
  }

  return {
    data: data?.filter(({ categories_slugs }) =>
      categories_slugs.some((slug) => categoriesToInclude.includes(slug)),
    ),
  };
};
