import { Carousel, colors } from '@lamimed/pantheon';

import * as S from './styles';

interface SlideProps {
  controlsColor?: keyof typeof colors;
}

const Slide = ({
  children,
  controlsColor = 'secondary',
}: React.PropsWithChildren<SlideProps>) => {
  return children ? (
    <S.Wrapper>
      <Carousel controlsColor={controlsColor}>{children}</Carousel>
    </S.Wrapper>
  ) : (
    <></>
  );
};

export default Slide;
