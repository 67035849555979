import {
  Grid,
  NewTypography as Typography,
  useViewport,
} from '@lamimed/pantheon';
import Image from 'next/legacy/image';

import { CategoriesList } from 'components/CategoriesList';

import * as S from './styles';

import img from '/public/images/illustration/categories/homecare_new.jpg';

export const Categories: React.FC = () => {
  const { greaterThan } = useViewport();

  return (
    <>
      <Grid.Wrapper>
        <S.Container>
          <div>
            <Typography as="h2" variant="heading_2" color="text__primary">
              Lami ti aiuta ovunque tu sia
            </Typography>

            <Typography
              marginBottom={greaterThan('tablet') ? 'none' : 'medium'}
            >
              Scegli uno dei nostri servizi a domicilio o una video visita e
              prenota in pochi click.
            </Typography>
          </div>

          <S.ImageWrapper>
            <Image
              src={img}
              layout="fill"
              objectFit="cover"
              objectPosition={greaterThan('tablet') ? 'initial' : 'top center'}
              quality={100}
              placeholder="blur"
            />
          </S.ImageWrapper>
        </S.Container>
      </Grid.Wrapper>

      <CategoriesList />
    </>
  );
};
