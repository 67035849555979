import BusinessAndLeadersBlackAndWhiteLogo from '/public/images/talk_about_us/busines-leaders.png';
import BusinessAndLeadersColorfulLogo from '/public/images/talk_about_us/busines-leaders-color.png';
import CorriereDellaSeraBlackAndWhiteLogo from '/public/images/talk_about_us/corriere-della-sera.png';
import CorriereDellaSeraColorfulLogo from '/public/images/talk_about_us/corriere-della-sera-color.png';
import CusanoTvItaliaBlackAndWhiteLogo from '/public/images/talk_about_us/cusano-tv-italia.png';
import CusanoTvItaliaColorfulLogo from '/public/images/talk_about_us/cusano-tv-italia-color.png';
import Isole24OreBlackAndWhiteLogo from '/public/images/talk_about_us/ilsole-24-ore.png';
import Isole24OreColorfulLogo from '/public/images/talk_about_us/ilsole-24-ore-color.png';
import InsurZineBlackAndWhiteLogo from '/public/images/talk_about_us/insur-zine.png';
import InsurZineColorfulLogo from '/public/images/talk_about_us/insur-zine-color.png';
import InsuranceUpBlackAndWhiteLogo from '/public/images/talk_about_us/insurance-up.png';
import InsuranceUpColorfulLogo from '/public/images/talk_about_us/insurance-up-color.png';
import LaRepubblicaBlackAndWhiteLogo from '/public/images/talk_about_us/la-repubblica.png';
import LaRepubblicaColorfulLogo from '/public/images/talk_about_us/la-repubblica-color.png';
import MillionaireBlackAndWhiteLogo from '/public/images/talk_about_us/millionaire.png';
import MillionaireColorfulLogo from '/public/images/talk_about_us/millionaire-color.png';
import QuiFinanzaBlackAndWhiteLogo from '/public/images/talk_about_us/qui-finanza.png';
import QuiFinanzaColorfulLogo from '/public/images/talk_about_us/qui-finanza-color.png';
import SanitaDigitaleBlackAndWhiteLogo from '/public/images/talk_about_us/sanita-digitale.png';
import SanitaDigitaleColorfulLogo from '/public/images/talk_about_us/sanita-digitale-color.png';
import StartupItaliaBlackAndWhiteLogo from '/public/images/talk_about_us/startup-italia.png';
import StartupItaliaColorfulLogo from '/public/images/talk_about_us/startup-italia-color.png';
import WellTvBlackAndWhiteLogo from '/public/images/talk_about_us/well-tv.png';
import WellTvColorfulLogo from '/public/images/talk_about_us/well-tv-color.png';

export const TalkAboutUsImages = [
  [
    {
      name: 'Ilsole 24 ore',
      blackAndWhite: Isole24OreBlackAndWhiteLogo,
      colorful: Isole24OreColorfulLogo,
      href: 'https://www.linkedin.com/feed/update/urn:li:activity:6889183669605429248',
    },
    {
      name: 'Corriere Della Sera',
      blackAndWhite: CorriereDellaSeraBlackAndWhiteLogo,
      colorful: CorriereDellaSeraColorfulLogo,
      href: 'https://nuvola.corriere.it/2021/11/29/sono-tornato-in-italia-per-dar-vita-alla-mia-startup-innovativa/',
    },
  ],
  [
    {
      name: 'Qui Finanza',
      blackAndWhite: QuiFinanzaBlackAndWhiteLogo,
      colorful: QuiFinanzaColorfulLogo,
      href: 'https://quifinanza.it/pmi-digitali/video/allarme-medici-startup-cura-casa-lami/594051/',
    },
    {
      name: 'La Repubblica',
      blackAndWhite: LaRepubblicaBlackAndWhiteLogo,
      colorful: LaRepubblicaColorfulLogo,
      href: 'https://www.repubblica.it/salute/native/2022/03/14/news/assistenza_sanitaria_a_portata_di_click-340666889/',
    },
  ],
  [
    {
      name: 'Startup Italia',
      blackAndWhite: StartupItaliaBlackAndWhiteLogo,
      colorful: StartupItaliaColorfulLogo,
      href: 'https://startupitalia.eu/74096-20220131-lami-la-startup-della-salute-nata-durante-il-lockdown',
    },
    {
      name: 'Millionaire',
      blackAndWhite: MillionaireBlackAndWhiteLogo,
      colorful: MillionaireColorfulLogo,
      href: 'https://www.linkedin.com/feed/update/urn:li:activity:6894232461320998912',
    },
  ],
  [
    {
      name: 'Insurance Up',
      blackAndWhite: InsuranceUpBlackAndWhiteLogo,
      colorful: InsuranceUpColorfulLogo,
      href: 'https://www.insuranceup.it/it/startup/lami-la-startup-ehealth-italiana-che-porta-in-casa-lassistenza-medica/',
    },
    {
      name: 'Sanità Digitale',
      blackAndWhite: SanitaDigitaleBlackAndWhiteLogo,
      colorful: SanitaDigitaleColorfulLogo,
      href: 'https://www.sanita-digitale.com/cura/lami-il-tuo-medico-di-fiducia-ovunque-tu-sia/',
    },
  ],
  [
    {
      name: 'Insur Zine',
      blackAndWhite: InsurZineBlackAndWhiteLogo,
      colorful: InsurZineColorfulLogo,
      href: 'https://www.insurzine.com/2022/02/22/salute-la-startup-lami-guarda-al-settore-assicurativo/',
    },
    {
      name: 'Business and Leaders',
      blackAndWhite: BusinessAndLeadersBlackAndWhiteLogo,
      colorful: BusinessAndLeadersColorfulLogo,
      href: 'https://businessandleaders.it/2021/11/06/lami-la-startup-che-porta-la-salute-a-casa-tua/',
    },
  ],
  [
    {
      name: 'Well Tv',
      blackAndWhite: WellTvBlackAndWhiteLogo,
      colorful: WellTvColorfulLogo,
      href: 'https://www.facebook.com/watch/live/?extid=NS-UNK-UNK-UNKAN_GK0T-GK1C&ref=watch_permalink&v=456027435916288',
    },
    {
      name: 'Cusano TV Italia',
      blackAndWhite: CusanoTvItaliaBlackAndWhiteLogo,
      colorful: CusanoTvItaliaColorfulLogo,
      href: 'https://www.youtube.com/watch?v=an9KB1hdImc',
    },
  ],
];
