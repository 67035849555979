import { Card, NewTypography as Typography, Timer } from '@lamimed/pantheon';

import { NewThumbnailDivider } from 'components/NewThumbnailDivider';
import Slide from 'components/Slide';

import { SeeMore } from './containers/SeeMore';
import { useGetPosts } from './useGetPosts';

import * as S from './styles';

export const StayUpdated: React.FC = () => {
  const { data } = useGetPosts();

  return (
    <>
      <S.SlideWrapper>
        <NewThumbnailDivider
          title="Lami news"
          description="Scopri le ultime novità dal mondo della salute."
        />

        <Slide controlsColor="dark">
          {data?.map(
            ({ id, title, image, thumbnail_image, url, reading_time }) => (
              <S.LinkProxy key={id} href={url}>
                <Card variant="hero">
                  <S.CardImage
                    src={image}
                    alt={title}
                    fill
                    placeholder="blur"
                    blurDataURL={thumbnail_image}
                  />

                  <Card.Body variant="hero">
                    <Typography
                      fontWeight="bold"
                      variant="heading_5"
                      color="text__light"
                      marginBottom="small"
                    >
                      {title}
                    </Typography>

                    <S.Time>
                      <Timer color="light" />

                      <Typography
                        variant="body_text_3"
                        marginBottom="none"
                        color="text__light"
                        lineHeight="none"
                      >
                        {`${reading_time} min`}
                      </Typography>
                    </S.Time>
                  </Card.Body>
                </Card>
              </S.LinkProxy>
            ),
          )}

          <SeeMore />
        </Slide>
      </S.SlideWrapper>
    </>
  );
};
