import { colors, media, radius, shadows, spacing } from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

import { NextSectionButtonProps } from '.';

export const ChevronIcon = styled.button<
  Pick<NextSectionButtonProps, 'variant'>
>`
  ${({ variant = 'minimal' }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.light};
    box-shadow: ${shadows.soft};
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    ${variant === 'minimal' &&
    `margin-top: calc(${spacing.xlarge} - ${spacing.small})`};
    ${variant === 'with_content' && `top: -${spacing.small}`};

    ${media.greaterThan('tablet')`
      ${
        variant === 'minimal' &&
        `margin-top: calc(${spacing.xxlarge} - ${spacing.small});`
      }
    `}
  `};
`;

interface ContainerProps {
  bgColor?: NextSectionButtonProps['bgColor'];
  isSafari?: boolean;
}

const positionModifiers = (isSafari: ContainerProps['isSafari']) => {
  if (isSafari) {
    // NOTE: Details at https://github.com/lami-health/website/pull/1695
    return css`
      bottom: -1px;
      width: 100%;
    `;
  }

  return css`
    bottom: 0;
    width: 100%;
  `;
};

export const Container = styled.div<ContainerProps>`
  ${({ bgColor = 'neutral_extra_light', isSafari = false }) => css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors[bgColor]};
    padding: ${spacing.small};
    border-top-left-radius: ${radius.medium};
    border-top-right-radius: ${radius.medium};

    ${positionModifiers(isSafari)};
  `};
`;
