import { useCallback, useLayoutEffect, useState } from 'react';
import { useViewport } from '@lamimed/pantheon';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { useSmallMobileScreen } from 'hooks/useSmallMobileScreen';

import { b2bUrl } from 'utils/links';

import * as S from './styles';

import mainHeroImg from '/public/images/main-hero-img.png';

export const Hero: React.FC = () => {
  const { greaterThan, lessThan } = useViewport();
  const { isOnSmallMobileScreen } = useSmallMobileScreen();
  const [screenHeight, setScreenHeight] = useState<number>();

  useLayoutEffect(() => {
    setScreenHeight(window?.innerHeight);
  }, []);

  const resizeImage = useCallback(() => {
    const imageSizes = {
      isSmallMobile: { width: 185, height: 180 },
      isTabletOrSmaller: { width: 253, height: 270 },
      isGreaterThanTablet: { width: 340, height: 356 },
    };

    if (isOnSmallMobileScreen) {
      return imageSizes['isSmallMobile'];
    }

    if (greaterThan('tablet')) {
      return imageSizes['isGreaterThanTablet'];
    }

    return imageSizes['isTabletOrSmaller'];
  }, [greaterThan, isOnSmallMobileScreen]);

  return (
    <S.HeroBackground deviceHeight={screenHeight}>
      <S.Wrapper>
        <S.MainCallToAction as="header">
          <S.HeroImageWrapper>
            <Image
              src={mainHeroImg}
              alt="Hero"
              width={resizeImage().width}
              height={resizeImage().height}
              layout={greaterThan('tablet') ? 'responsive' : 'intrinsic'}
              objectFit="contain"
              quality={100}
              placeholder="blur"
            />
          </S.HeroImageWrapper>

          <S.ContentWrapper>
            <S.TextWrapper>
              <S.Title color="text__light" textAlign="left" forwardedAs="h1">
                La tua guida verso il giusto percorso di cura
              </S.Title>

              <S.Description textAlign="left" color="text__light">
                Lami è la piattaforma di primary care che aiuta le persone a
                prendere le giuste decisioni per la loro salute, facilitando
                l’interpretazione dei sintomi e l’interazione con il medico.
              </S.Description>
            </S.TextWrapper>

            <Link href={b2bUrl} passHref legacyBehavior>
              <S.Button
                forwardedAs="a"
                extended={lessThan('tablet')}
                shrinked={greaterThan('tablet')}
                size="large"
                color="secondary"
              >
                Area associati
              </S.Button>
            </Link>
          </S.ContentWrapper>
        </S.MainCallToAction>
      </S.Wrapper>
    </S.HeroBackground>
  );
};
