import { gap, media, spacing } from '@lamimed/pantheon';
import styled from 'styled-components';

import { CallToAction } from 'components/CallToAction/styles';
import {
  headerHeightDesktop,
  headerHeightMobile,
} from 'components/Header/styles';
import { TextWrapper } from 'components/TextWrapper/styles';

export const MainCallToAction = styled(CallToAction)`
  margin-top: ${headerHeightMobile};

  ${media.greaterThan('tablet')`
    margin-top: ${headerHeightDesktop};
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    ${TextWrapper} {
      max-width: 43rem;
      text-align: left;
    }

    ${gap(spacing.medium)};
  `}
`;
