import {
  BulletCarousel,
  Grid,
  NewTypography as Typography,
  useViewport,
} from '@lamimed/pantheon';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Category } from 'models/Category';

import { useSWRGet } from 'hooks/useSWRGet';

import { getCarouselArrowSize } from 'utils/getCarouselArrowSize';
import { sendToErrorTracking } from 'utils/sendToErrorTracking';

import * as S from './styles';

export const CategoriesList: React.FC = () => {
  const router = useRouter();
  const { greaterThan } = useViewport();

  const { data, error } = useSWRGet<Category[]>(`/top-categories`);

  if (error) {
    sendToErrorTracking({ error, where: 'CategoriesList', context: data });

    router.push('/500');
  }

  const slidesToShow = greaterThan('tablet') ? 4 : 2;

  return (
    <S.Wrapper>
      <Grid.Wrapper>
        {!!data?.length && (
          <BulletCarousel
            slidesToShow={slidesToShow}
            variant={greaterThan('tablet') ? 'centeredControls' : undefined}
            slidesToScroll={greaterThan('tablet') ? 1 : 2}
            cellSpacing={20}
            controlsTheme="light"
            withoutControls={data.length <= slidesToShow}
            arrowSize={getCarouselArrowSize(greaterThan('tablet'))}
          >
            {data?.map((category) => (
              <Link
                key={category.id}
                href={`/${category.slug}`}
                passHref
                legacyBehavior
              >
                <S.Category>
                  <S.CategoryIcon>
                    <Image
                      src={`/images/categories_icons/${category.slug}.svg`}
                      width={40}
                      height={40}
                      quality={100}
                    />
                  </S.CategoryIcon>

                  <Typography
                    variant="heading_5"
                    color="text__light"
                    textAlign="center"
                    marginBottom="none"
                  >
                    {category.title}
                  </Typography>

                  <Typography
                    variant="body_text_3"
                    color="text__light"
                    marginBottom="none"
                    lineHeight="small"
                    textAlign="center"
                  >
                    {category.shortDescription}
                  </Typography>
                </S.Category>
              </Link>
            ))}
          </BulletCarousel>
        )}
      </Grid.Wrapper>
    </S.Wrapper>
  );
};
