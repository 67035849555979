import React, { HTMLAttributes } from 'react';
import {
  colors,
  NewTypography as Typography,
  spacing,
} from '@lamimed/pantheon';

import {
  NextSectionButton,
  NextSectionButtonProps,
} from 'templates/AboutUs/containers/NextSectionButton';

import * as S from './styles';

export interface SectionWrapperProps extends HTMLAttributes<HTMLDivElement> {
  paddingTop?: keyof typeof spacing;
  paddingBottom?: keyof typeof spacing;
  additionalPadding?: string;
  backgroundColor?: keyof typeof colors;
  polygonBackgroundColor?: keyof typeof colors | 'none';
  border?: 'default' | 'radius';
  nextSectionContent?: string;
  nextSectionId?: string;
  nextSectionButtonVariant?: NextSectionButtonProps['variant'];
}

export const SectionWrapper: React.FC<
  React.PropsWithChildren<SectionWrapperProps>
> = ({
  paddingTop,
  paddingBottom,
  additionalPadding = '0rem',
  backgroundColor = 'light',
  polygonBackgroundColor = 'none',
  border = 'default',
  nextSectionContent,
  nextSectionId,
  nextSectionButtonVariant = 'minimal',
  children,
  ...props
}) => (
  <S.Wrapper
    position={
      nextSectionButtonVariant === 'with_content' ? 'relative' : 'initial'
    }
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
    additionalPadding={additionalPadding}
    backgroundColor={backgroundColor}
    polygonBackgroundColor={polygonBackgroundColor}
    border={border}
    {...props}
  >
    {polygonBackgroundColor !== 'none' ? (
      <S.Polygon polygonBackgroundColor={polygonBackgroundColor}>
        {children}
      </S.Polygon>
    ) : (
      <>{children}</>
    )}

    {nextSectionButtonVariant === 'with_content' && (
      <NextSectionButton
        variant={nextSectionButtonVariant}
        nextSectionId={nextSectionId || ''}
      >
        <Typography variant="body_text_3" marginBottom="none">
          {nextSectionContent}
        </Typography>
      </NextSectionButton>
    )}
  </S.Wrapper>
);
