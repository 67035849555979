import {
  borderWidths,
  colors,
  gap,
  media,
  opacity,
  radius,
  spacing,
} from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

interface ColumnProps {
  isOnSmallScreen: boolean;
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${gap(spacing.medium)};

  ${({ isOnSmallScreen }) =>
    isOnSmallScreen &&
    css`
      ${gap(spacing.small)};
    `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;

  &:first-child {
    opacity: 1;
    pointer-events: none;
  }

  &:last-child {
    opacity: 0;
  }

  ${media.greaterThan('tablet')`
    &:hover {
      opacity: 1;
    }
  `}
`;

export const CardLink = styled.a`
  height: 6rem;
  position: relative;
  border: ${borderWidths.thin} solid ${colors.neutral};
  border-radius: ${radius.small};
  background-color: ${colors.dark};
  opacity: ${opacity.hard};

  ${media.greaterThan('tablet')`
    &:hover {
      background-color: ${colors.neutral_extra_light};
      opacity: 1;
    }
  `}
`;
