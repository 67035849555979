import { colors, media, radius, spacing } from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

import { headerHeightDesktop } from 'components/Header/styles';

import { SectionWrapperProps } from './index';

const borderVariants = {
  default: css`
    border-radius: 0;
  `,
  radius: css`
    border-radius: 0;

    ${media.greaterThan('tablet')`
      border-radius: ${radius.medium} ${radius.medium} 0 0;
    `}
  `,
};

type WrapperProps = {
  position?: 'relative' | 'initial';
} & SectionWrapperProps;

export const Wrapper = styled.div<WrapperProps>`
  ${({
    paddingTop,
    paddingBottom,
    additionalPadding,
    backgroundColor,
    border,
    position = 'initial',
  }) =>
    css`
      position: ${position};
      background: ${backgroundColor ? colors[backgroundColor] : 'transparent'};
      padding-bottom: ${paddingBottom
        ? `calc(${spacing[paddingBottom]} + ${additionalPadding})`
        : spacing.xlarge};
      padding-top: ${paddingTop ? spacing[paddingTop] : spacing.xlarge};

      ${border && borderVariants[border]};

      ${media.greaterThan('tablet')`
        padding-bottom: ${
          paddingBottom
            ? `calc(${spacing[paddingBottom]} + ${additionalPadding})`
            : spacing.xxlarge
        };
        padding-top:  ${paddingTop ? spacing[paddingTop] : spacing.xxlarge};
      `}
    `}
`;

export const Polygon = styled.div<SectionWrapperProps>`
  ${({ polygonBackgroundColor }) =>
    polygonBackgroundColor !== 'none' &&
    css`
      padding-bottom: 0;
      background: ${polygonBackgroundColor
        ? colors[polygonBackgroundColor]
        : 'transparent'};

      ${media.greaterThan('tablet')`
        height: calc(100vh - ${headerHeightDesktop});
        position: relative;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
        border-radius: 0;
      `}

      ${media.greaterThan('desktop_large')`
        max-height: 70rem;
      `}
    `}
`;
