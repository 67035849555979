import { BulletCarousel, Grid, useViewport } from '@lamimed/pantheon';
import Image from 'next/image';
import Link from 'next/link';

import { useSmallMobileScreen } from 'hooks/useSmallMobileScreen';

import { getCarouselArrowSize } from 'utils/getCarouselArrowSize';

import { CallToAction } from 'components/CallToAction/styles';
import { NewThumbnailDivider } from 'components/NewThumbnailDivider';

import { TalkAboutUsImages } from './images';

import * as S from './styles';

export const TalkAboutUs: React.FC = () => {
  const { lessThan, greaterThan } = useViewport();
  const { isOnSmallMobileScreen } = useSmallMobileScreen();

  return (
    <CallToAction>
      <Grid.Wrapper>
        <NewThumbnailDivider
          title="Parlano di noi"
          description="Grazie ai partner che ci appoggiano nel nostro lavoro quotidiano:"
        />

        <BulletCarousel
          slidesToShow={lessThan('tablet') ? 2 : 4}
          slidesToScroll={lessThan('tablet') ? 1 : 2}
          arrowSize={getCarouselArrowSize(greaterThan('tablet'))}
          cellSpacing={lessThan('tablet') ? 20 : 40}
        >
          {TalkAboutUsImages.map((talkAboutUs, i) => (
            <S.Column key={i} isOnSmallScreen={isOnSmallMobileScreen}>
              <Link href={talkAboutUs[0].href} passHref legacyBehavior>
                <S.CardLink rel="noopener noreferrer nofollow" target="_blank">
                  <S.ImageContainer>
                    <Image
                      src={talkAboutUs[0].blackAndWhite}
                      width={lessThan('tablet') ? 100 : 140}
                      height={lessThan('tablet') ? 30 : 40}
                      quality={100}
                      placeholder="blur"
                      alt={`${talkAboutUs[0].name} logo`}
                    />
                  </S.ImageContainer>

                  <S.ImageContainer>
                    <Image
                      aria-hidden
                      src={talkAboutUs[0].colorful}
                      width={lessThan('tablet') ? 100 : 140}
                      height={lessThan('tablet') ? 30 : 40}
                      quality={100}
                      placeholder="blur"
                      alt={`${talkAboutUs[0].name} logo`}
                    />
                  </S.ImageContainer>
                </S.CardLink>
              </Link>

              {talkAboutUs[1] && (
                <Link href={talkAboutUs[1].href} passHref legacyBehavior>
                  <S.CardLink
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <S.ImageContainer>
                      <Image
                        src={talkAboutUs[1].blackAndWhite}
                        width={lessThan('tablet') ? 100 : 140}
                        height={lessThan('tablet') ? 30 : 40}
                        quality={100}
                        placeholder="blur"
                        alt={`${talkAboutUs[1].name} logo`}
                      />
                    </S.ImageContainer>

                    <S.ImageContainer>
                      <Image
                        aria-hidden
                        src={talkAboutUs[1].colorful}
                        width={lessThan('tablet') ? 100 : 140}
                        height={lessThan('tablet') ? 30 : 40}
                        quality={100}
                        placeholder="blur"
                        alt={`${talkAboutUs[1].name} logo`}
                      />
                    </S.ImageContainer>
                  </S.CardLink>
                </Link>
              )}
            </S.Column>
          ))}
        </BulletCarousel>
      </Grid.Wrapper>
    </CallToAction>
  );
};
