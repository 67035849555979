import { useEffect, useRef, useState } from 'react';

export const useSmallMobileScreen = () => {
  const [isOnSmallMobileScreen, setIsOnSmallMobileScreen] = useState(false);
  const node = useRef<HTMLElement | null>(null);

  useEffect(() => {
    node.current = document.getElementById('control-height');
  }, []);

  useEffect(() => {
    const getScreenHeight = () => {
      // NOTE: We're using this instead of the standard innerHeight and outerHeight because those methods don't count the address bar height.
      // More details at: https://lamimed.atlassian.net/browse/TEC-994
      const height = node?.current?.clientHeight || 0;

      // iPhone8 screen height is 667 which is the smallest device height we support
      height <= 667
        ? setIsOnSmallMobileScreen(true)
        : setIsOnSmallMobileScreen(false);
    };

    getScreenHeight();

    window.addEventListener('resize', getScreenHeight);

    return () => window.removeEventListener('resize', getScreenHeight);
  }, []);

  return { isOnSmallMobileScreen };
};
